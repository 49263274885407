<template>
	<div class="bg-white dbNotes-card">
		<div class="px-5 pt-5 d-flex">
			<v-spacer></v-spacer>
			<!-- <v-btn
        v-if="dbNotesCheckedIds.length > 0"
        tile
        depressed
        fab
        small
        height="30"
        width="30"
        class="mr-2 red lighten-4"
        @click="removeNotes()"
      >
        <v-icon size="16" icon color="red">mdi-delete</v-icon>
      </v-btn> -->
			<v-btn
				tile
				depressed
				fab
				small
				height="30"
				width="30"
				class="mr-2 blue lighten-4"
				@click="addNote()"
			>
				<v-icon size="16" icon color="blue">mdi-plus</v-icon>
			</v-btn>
		</div>
		<div class="py-2 pt-2">
			<div class="px-5 overflow-auto d-flex flex-column" style="max-height: 500px; height: 500px">
				<table class="w-100 note-table" v-if="dbNotes.length">
					<tr v-for="(row, index) in dbNotes" :key="index" class="blue lighten-5">
						<td valign="top" width="2%">
							<v-checkbox
								hide-details
								v-model="dbNotes[index].check"
								v-on:change="disbaleData(index)"
								class="ma-1"
								color="blue"
							></v-checkbox>
						</td>
						<td class="" width="85%">
							<v-textarea
								:disabled="row.readOnly"
								class="note-area border-0"
								name="input-7-1"
								filled
								auto-grow
								hide-details
								rows="1"
								width="100%"
								:ref="`textarea-${index}`"
								v-model="dbNotes[index].text"
								@keypress.prevent.enter="addNoteNextLine()"
								@focus="updatedIndex(index)"
							>
							</v-textarea>
						</td>
						<td valign="top" width="5%" align="center">
							<v-btn
								v-if="dbNotes.length > 1"
								tile
								depressed
								fab
								small
								style="margin-bottom: -20px !important"
								height="30"
								width="30"
								:disabled="row.readOnly"
								class="ml-2 red lighten-4"
								@click="removeNote(index)"
							>
								<v-icon size="16" icon color="red">mdi-delete</v-icon>
							</v-btn>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>
<style>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
	/* background-color: #ddecf7 !important; */
	background: rgb(221 221 221 / 6%) !important;
}
</style>
<script>
import { PATCH } from "@/core/services/store/request.module";
import { filter } from "lodash";
export default {
	name: "mine-notes",
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			activeIndex: null,
			dbNotesCheckedIds: [],
			dbNotes: [
				{
					id: 0,
					text: null,
					check: false,
				},
			],
			timeout: null,
			timeoutLimit: 500,
		};
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		notes: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	watch: {
		value: {
			deep: true,
			handler(param) {
				this.dbNotes = param;
			},
		},
		dbNotes: {
			deep: true,
			handler(param) {
				this.$emit("input", param);
			},
		},
	},
	methods: {
		disbaleData(value) {
			this.dbNotes[value].readOnly = this.dbNotes[value].check;
		},
		updateNotes() {
			const _this = this;

			const db_notes = filter(_this.dbNotes, function (param) {
				return param.text !== null;
			});

			clearTimeout(_this.timeout);

			if (db_notes.length > 0) {
				_this.timeout = setTimeout(function () {
					_this.$store
						.dispatch(PATCH, { url: "notes", data: { notes: db_notes } })
						.then(({ data }) => {
							console.log({ data });
						})
						.catch((error) => {
							console.log({ error });
						});
				}, _this.timeoutLimit);
			}
		},
		addNote() {
			this.dbNotes.push({
				id: Date.now(),
				text: null,
				readOnly: false,
				check: false,
			});
			const length = this.dbNotes.length;
			this.$nextTick(() => {
				const element = this.$refs[`textarea-${length - 1}`];
				const area = element[0].$el.querySelector("textarea");
				area.focus();
			});
		},
		addNoteNextLine() {
			if (this.activeIndex >= 0) {
				this.dbNotes.splice(this.activeIndex + 1, 0, {
					id: Date.now(),
					text: null,
				});
				this.$nextTick(() => {
					const element = this.$refs[`textarea-${this.activeIndex + 1}`];
					const area = element[0].$el.querySelector("textarea");
					area.focus();
				});
			}
		},
		removeNotes() {
			const updatedNotes = this.dbNotes.filter((row) => {
				return !this.dbNotesCheckedIds.includes(row.id);
			});
			this.dbNotesCheckedIds = [];
			this.dbNotes = updatedNotes;
		},
		removeNote(index) {
			/*  alert(index) */
			if (this.dbNotes[index]) {
				this.dbNotes.splice(index, 1);
				if (this.dbNotes.length) {
					this.$nextTick(() => {
						const element = this.$refs[`textarea-${index - 1}`];
						const area = element[0].$el.querySelector("textarea");
						area.focus();
					});
				}
			}
		},
		updatedIndex(index) {
			this.activeIndex = index;
		},
	},
	mounted() {
		this.dbNotes = this.value;
		this.dbNotes = [
			{
				id: Date.now(),
				text: null,
				readOnly: false,
				check: false,
			},
		];
	},
};
</script>
