<template>
	<div v-if="drawer">
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 200"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ titleStatus }} Task</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						v-on:click="update_or_create()"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="form"
					id="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3">
							<div class="mb-3" v-if="related == null">
								<div class="d-flex pt-1">
									<label class="fw-500">Related To</label>
								</div>
								<v-radio-group row hide-details class="mt-0" v-model="task.related_to">
									<template>
										<v-radio label="Lead" value="lead"></v-radio>
										<v-radio label="Task" value="task"></v-radio>
									</template>
								</v-radio-group>
							</div>

							<div
								class="mb-3"
								v-if="task.related_to != null && task.related_to != 'task' && related == null"
							>
								<div class="d-flex pt-1">
									<label class="fw-600 required text-capitalize">Company Name </label>
								</div>
								<div>
									<div v-if="task.related_to != null" class="d-flex">
										<v-autocomplete
											:items="dataLists"
											item-text="text"
											placeholder="Select Lead"
											item-value="value"
											depressed
											hide-details
											outlined
											v-model.trim="task.related_id"
											:rules="[vrules.required(task.related_id, 'Related Type')]"
											:class="{
												required: !task.related_id,
											}"
										>
											<template v-slot:selection="data">
												<span class="text-uppercase">
													{{ data.item.text }} ({{ data.item.lead_barcode }})
												</span>
											</template>
											<template v-slot:item="data">
												<template>
													<v-list-item-content>
														<v-list-item-title
															>{{ data.item.text }}({{ data.item.lead_barcode }})</v-list-item-title
														>
													</v-list-item-content>
												</template>
											</template>
										</v-autocomplete>
										<!-- <v-autocomplete
										:items="dataLists"
										item-text="text"
										item-value="value"
										depressed
										hide-details
										outlined
										v-model="task.related_id"
									>
								     </v-autocomplete> -->
										<v-icon v-on:click="createLead" class="primary--text"> mdi-cog </v-icon>
									</div>
								</div>
							</div>
						</div>
						<div class="mb-3" v-if="taskData && taskData.customer_name">
							<div class="d-flex pt-1">
								<label class="fw-500">Person Incharge :</label>
								<span class="ml-3">
									<b>{{ taskData.customer_name }}</b></span
								>
							</div>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Title </label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								placeholder="Title"
								v-model="task.title"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(task.title, 'Title')]"
								:class="{
									required: !task.title,
								}"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Start Date</label>
							</div>
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="start-date"
								placeholder="Start Date"
								v-model="task.start_date"
								:rules="[vrules.required(task.start_date, 'Start Date')]"
								:class="{
									required: !task.start_date,
								}"
							></DatePicker>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Due Date</label>
							</div>
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="start-date"
								placeholder="Due Date"
								v-model="task.due_date"
								:rules="[vrules.required(task.due_date, 'Due Date')]"
								:class="{
									required: !task.due_date,
								}"
							></DatePicker>
						</div>
						<template v-if="titleStatus == 'Create' || titleStatus == null">
							<div class="mb-3">
								<div class="d-flex pt-1">
									<label class="fw-500">Reminder Type</label>
								</div>
								<AutoCompleteInput
									dense
									id="reminder-type"
									hide-details
									placeholder="Select Reminder Type"
									:items="reminder_type_items"
									:disabled="pageLoading"
									:loading="pageLoading"
									item-text="text"
									v-model="task.reminder_type"
									item-value="value"
									v-on:change="get_reminder_values()"
								>
								</AutoCompleteInput>
							</div>
							<div class="mb-3">
								<div class="d-flex pt-1">
									<label class="fw-500 required"
										><!-- <template v-if="task.reminder_type != 1">Start </template> -->Reminder Start
										Date</label
									>
								</div>
								<!-- <DatePicker
									dense
									id="task-start-date"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="task.reminder_start_date"
									:placeholder="'Select Reminder Start Date...'"
									v-on:change="get_reminder_values()"
									:rules="[
										vrules.required(
											task.reminder_start_date,
											task.reminder_type != 1 ? 'Reminder Start Date' : 'Reminder Start Date'
										),
									]"
									:class="{ required: !task.reminder_start_date }"
								></DatePicker> -->
								<DateTimePicker
									dense
									id="task-start-date"
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="task.reminder_start_date"
									:placeholder="'Select Reminder Start Date...'"
									v-on:change="get_reminder_values()"
									:rules="[
										vrules.required(
											task.reminder_start_date,
											task.reminder_type != 1 ? 'Reminder Start Date' : 'Reminder Start Date'
										),
									]"
									:class="{ required: !task.reminder_start_date }"
								></DateTimePicker>
							</div>
							<div class="mb-3" v-if="task.reminder_type != 1">
								<div class="d-flex pt-1">
									<label class="fw-500">Frequency</label>
								</div>
								<TextInput
									v-if="false"
									dense
									id="task-frequency"
									hide-details
									type="number"
									placeholder="Enter Frequency..."
									:disabled="pageLoading"
									:loading="pageLoading"
									class="mt-0"
									v-model="task.reminder_frequency"
									v-on:keyup="get_reminder_values()"
								></TextInput>

								<v-text-field
									type="Number"
									v-mask="'####'"
									depressed
									hide-details
									outlined
									placeholder="Enter Frequency..."
									v-model="task.reminder_frequency"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-on:keyup="get_reminder_values()"
									@wheel="$event.target.blur()"
								></v-text-field>
							</div>
							<div class="mb-3" v-if="task.reminder_type != 1">
								<div class="d-flex pt-1">
									<label class="fw-500">Reminder End Date</label>
								</div>
								<!-- <DatePicker
									dense
									id="task-end-date"
									hide-details
									placeholder="Select Reminder End Date..."
									:disabled="pageLoading"
									:loading="pageLoading"
									readonly
									v-model="task.reminder_end_date"
								>
								</DatePicker> -->
								<DateTimePicker
									dense
									id="task-end-date"
									hide-details
									placeholder="Select Reminder End Date..."
									:disabled="pageLoading"
									:loading="pageLoading"
									readonly
									v-model="task.reminder_end_date"
								></DateTimePicker>
							</div>
							<div class="mb-3">
								<v-radio-group row hide-details class="mt-0" v-model="task.callType">
									<v-radio color="green" label="Call" value="call"></v-radio>
									<v-radio color="red" label="Email" value="email"></v-radio>
								</v-radio-group>
							</div>
						</template>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Attachment</label>
							</div>
						</div>
						<template v-for="(file, index) in files">
							<div class="d-flex mb-2" :key="index">
								<div class="w-40 pr-3" style="margin-top: -12px; overflow: hidden">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</div>
								<div class="w-40 pr-3" style="margin-top: -12px">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
									></TextInput>
								</div>
								<div class="w-10 pr-3" style="margin-top: -12px">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										class="mt-3"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
								<div class="w-10 pr-3" style="margin-top: -12px">
									<v-btn
										color="blue darken-4 white--text"
										class="mt-3 ml-2"
										tile
										depressed
										v-on:click="addMore()"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</div>
							</div>
						</template>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Priority</label>
							</div>
							<v-radio-group row hide-details class="mt-0" v-model="task.priority">
								<v-radio color="cyan" label="LOW" value="low"></v-radio>
								<v-radio color="green" label="MEDIUM" value="medium"></v-radio>
								<v-radio color="orange" label="HIGH" value="high"></v-radio>
								<v-radio color="blue" label="URGENT" value="urgent"></v-radio>
							</v-radio-group>
							<!-- <v-autocomplete
								:items="priorityList"
								item-text="text"
								item-value="value"
								placeholder="Priority"
								depressed
								hide-details
								outlined
								v-model="task.priority"
								:rules="[vrules.required(task.priority, 'Priority')]"
								:class="{
									required: !task.priority,
								}"
							></v-autocomplete> -->
						</div>
						<div class="mb-3 required">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Assigned To</label>
							</div>
							<v-autocomplete
								:items="userList"
								item-text="display_name"
								item-value="id"
								depressed
								:readonly="currentUser.role != 1 && currentUser.role != 4"
								placeholder="Assigned To"
								hide-details
								outlined
								hide-selected
								class="cuatom-drop"
								multiple
								color="blue darken-4"
								small-chips
								deletable-chips
								v-model="task.attendees"
								:rules="[vrules.required(task.attendees, 'Assigned To')]"
								:class="{
									required: !task.attendees,
								}"
							>
								<template v-slot:selection="data">
									<v-chip
										small
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="remove(data.item)"
									>
										<v-avatar left>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>
										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>

						<!-- <div class="d-flex pt-1" v-if="titleStatus == 'Create' || titleStatus == null">
							<label class="fw-500">Notes</label>
						</div> -->
						<div
							class="mb-3 custom-border"
							v-if="(false && titleStatus == 'Create') || titleStatus == null"
						>
							<ChecklistNotes v-model="notesdata"></ChecklistNotes>
							<!-- <div class="d-flex pt-1">
								<label class="fw-500">Description</label>
							</div>
							<ckeditor :editor="editor" v-model="task.description" :config="editorConfig"></ckeditor> -->
						</div>
					</div>
				</v-form>
			</div>
			<CreateInternalLead
				v-if="CreateLeadDialog"
				v-on:success="CreateLeadDialog = false"
				v-on:refresh="refreshAlldata"
				v-on:close="CreateLeadDialog = false"
				:create-lead-dialog="CreateLeadDialog"
			>
			</CreateInternalLead>
		</v-navigation-drawer>
	</div>
</template>
<style>
aside.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.v-navigation-drawer--right.v-navigation-drawer--temporary.theme--light {
	z-index: 99 !important;
}
</style>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { head, map, toSafeInteger /* toString */ } from "lodash";
import { mapGetters } from "vuex";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import MainMixin from "@/core/mixins/main.mixin.js";
import MomentJS from "moment-timezone";
import ChecklistNotes from "@/view/pages/leads/components/Checklist-Notes";
import { TaskEventBus } from "@/core/lib/task.form.lib";
import CreateInternalLead from "@/view/pages/leads/create/Create-Internal-Lead";
import DateTimePicker from "@/view/components/DateTimePicker";

export default {
	mixins: [MainMixin],
	name: "Address",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		titleStatus: {
			type: String,
		},
		taskData: {
			type: Object,
		},
		attendies: {
			type: Object,
		},
		related: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		taskData: {
			handler(param) {
				this.int(param);

				if (param && param.remindersdata) {
					this.remindersdata = JSON.parse(param.remindersdata);
				}
				this.task.id = param.id;
				this.task.title = param.title;
				this.task.start_date = param.start_date;

				this.task.due_date = param.due_date;
				if (this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_date) {
					this.task.reminder_start_date = this.remindersdata[0].reminder_date
						? MomentJS(this.remindersdata[0].reminder_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
						: null;
				}
				if (this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_end_date) {
					this.task.reminder_end_date = this.remindersdata[0].reminder_end_date
						? MomentJS(this.remindersdata[0].reminder_end_date, "YYYY-MM-DD HH:II:SS").format(
								"YYYY-MM-DD"
						  )
						: null;
				}
				if (this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_type) {
					this.task.reminder_type = this.remindersdata[0].reminder_type
						? this.remindersdata[0].reminder_type
						: 1;
				}
				if (this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_frequency) {
					this.task.reminder_frequency = this.remindersdata[0].reminder_frequency
						? this.remindersdata[0].reminder_frequency
						: 1;
				}
				this.task.priority = param.priority;
				this.task.related_to = param.related_to;
				this.task.related_id = param.related_id ? toSafeInteger(param.related_id) : 0;
				this.task.assigned_to = param.assigned_to;
				this.task.description = param.description;
				this.task.lead_id = param.relatedId;
				this.notesdata = JSON.parse(param.notesdata);
				this.getRelatedField(true);
			},
		},
		attendies: {
			handler(param) {
				this.int1(param);
				this.task.attendees = map(param.attendies, "id");
			},
		},
	},
	data() {
		return {
			CreateLeadDialog: false,
			formValid: true,
			pageLoading: false,
			today: MomentJS,
			editor: ClassicEditor,
			editorData: "<p>Description</p>",
			editorConfig: {},
			remindersdata: [],
			date_dialog: false,
			notesdata: [],
			due_date_dialog: false,
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			],

			task: {
				id: 0,
				title: null,
				start_date: null,
				due_date: null,
				priority: "low",
				reminder_start_date: this.formatDateTimeRaw(new Date()),
				reminder_end_date: null,
				reminder_type: 1,
				reminder_frequency: 1,
				attendees: [],
				related_to: "lead",
				related_id: 0,
				callType: "call",
				description: null,
				lead_id: 0,
				status: 0,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
			priorityList: [],
			userList: [],
			dataLists: [],
			relatedList: [
				{
					text: "Lead",
					value: "lead",
				},
				{
					text: "Customer",
					value: "customer",
				},
				{
					text: "Task",
					value: "task",
				},
			],
		};
	},
	components: {
		DatePicker,
		TextInput,
		AutoCompleteInput,
		ChecklistNotes,
		CreateInternalLead,
		DateTimePicker,
	},
	methods: {
		createLead() {
			this.CreateLeadDialog = true;
		},
		refreshAlldata() {
			this.getRelatedField(true);
		},
		int1(param) {
			this.task.attendees = map(param.attendies, "id");
			if (this.titleStatus != "Update") {
				this.task.attendees = [this.userId];
			}
		},
		int(param) {
			if (param.remindersdata) {
				this.remindersdata = JSON.parse(param.remindersdata);
			}
			this.task.id = param.id;
			this.task.title = param.title;
			this.task.start_date = param.start_date
				? param.start_date
				: new Date().toISOString().substr(0, 10);

			this.task.due_date = param.due_date ? param.due_date : new Date().toISOString().substr(0, 10);
			this.task.status = param.status;
			this.task.reminder_start_date =
				this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_date
					? MomentJS(this.remindersdata[0].reminder_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
					: this.formatDateTimeRaw(new Date());
			this.task.reminder_end_date =
				this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_end_date
					? MomentJS(this.remindersdata[0].reminder_end_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
					: null;
			this.task.reminder_type =
				this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_type
					? this.remindersdata[0].reminder_type
					: 1;
			this.task.reminder_frequency =
				this.remindersdata && this.remindersdata[0] && this.remindersdata[0].reminder_frequency
					? this.remindersdata[0].reminder_frequency
					: 1;
			this.task.priority = param.priority ? param.priority : "low";

			this.task.related_to = param.related_to ? param.related_to : "lead";

			this.task.related_id = param ? toSafeInteger(param.related_id) : 0;
			this.task.assigned_to = param.assigned_to;
			this.task.description = param.description;
			this.task.lead_id = param.relatedId;
			/* console.log(this.notesdata)
			this.notesdata =JSON.parse(param.notesdata); */

			this.getRelatedField(true);
		},
		get_reminder_values() {
			// if(!this.task.reminder_frequency){
			//     this.task.reminder_frequency = 1;
			// }
			let reminderParseDate = MomentJS(this.task.reminder_start_date);

			if (this.task.reminder_start_date) {
				for (var i = 0; i < this.task.reminder_frequency; i++) {
					if (this.task.reminder_type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
					}

					if (this.task.reminder_type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
					}

					if (this.task.reminder_type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
					}

					if (this.task.reminder_type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}

					if (this.task.reminder_type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
					}

					if (this.task.reminder_type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}
				}
				return (this.task.reminder_end_date = reminderParseDate.format("YYYY-MM-DD"));
			}
		},
		remove(item) {
			const index = this.task.attendees.indexOf(item.id);
			this.task.attendees.splice(index, 1);
		},
		getRelatedField(param) {
			this.dataLists = [];
			this.$store
				.dispatch(GET, { url: `related-field` })
				.then((data) => {
					this.dataLists = data[this.task.related_to];
					if (!param) {
						const firstEle = head(this.dataLists);

						if (firstEle && firstEle.value) {
							if (this.relatedId > 0) {
								this.task.related_id = toSafeInteger(this.relatedId);
							} else {
								this.task.related_id = toSafeInteger(firstEle.value);
							}
						}
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getTaskSetting() {
			this.$store
				.dispatch(GET, { url: `task-setting` })
				.then((data) => {
					this.priorityList = data.priority;
					this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}

			this.task[this.task.related_to] = toSafeInteger(this.task.related_id);
			this.pageLoading = true;
			let formData = new FormData();

			formData.append("title", this.task.title ? this.task.title : null);
			formData.append("start_date", this.task.start_date ? this.task.start_date : null);
			formData.append("due_date", this.task.due_date ? this.task.due_date : null);
			formData.append("priority", this.task.priority ? this.task.priority : null);
			if (this.task && this.task.reminder_start_date) {
				formData.append(
					"reminder_start_date",
					this.task.reminder_start_date ? this.task.reminder_start_date : null
				);
			}
			if (this.task && this.task.callType) {
				formData.append("callType", this.task.callType ? this.task.callType : "call");
			}
			if (this.task && this.task.reminder_end_date && this.task.reminder_type != 1) {
				formData.append(
					"reminder_end_date",
					this.task.reminder_end_date ? this.task.reminder_end_date : null
				);
			}
			if (this.task && this.task.reminder_type) {
				formData.append("reminder_type", this.task.reminder_type ? this.task.reminder_type : 1);
			}
			/* if (this.task && this.task.reminder_frequency) { */
			formData.append(
				"reminder_frequency",
				this.task.reminder_frequency ? this.task.reminder_frequency : 1
			);
			/* } */

			if (this.task && this.task.description) {
				formData.append("description", this.task.description ? this.task.description : null);
			}
			if (this.task && this.task.status) {
				formData.append("status", this.task.status ? this.task.status : 3);
			}
			formData.append("related_to", this.task.related_to ? this.task.related_to : this.related);
			if (this.task.related_to == "task") {
				formData.append("related_id", 0);
			} else {
				formData.append(
					"related_id",
					this.task.related_id ? toSafeInteger(this.task.related_id) : toSafeInteger(this.relatedId)
				);
			}
			formData.append("lead_id", this.task.lead_id > 0 ? toSafeInteger(this.task.related_id) : 0);

			for (let i = 0; i < this.files.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}
			if (this.task && this.task.attendees && this.task.attendees.length) {
				for (let i = 0; i < this.task.attendees.length; i++) {
					if (this.task && this.task.attendees && this.task.attendees[i]) {
						formData.append(`attendees[${i}]`, this.task.attendees[i]);
					}
				}
			}
			if (this.notesdata && this.notesdata.length) {
				for (let i = 0; i < this.notesdata.length; i++) {
					if (this.notesdata && this.notesdata[i]) {
						if (
							this.notesdata &&
							this.notesdata[i] &&
							this.notesdata &&
							this.notesdata[i].id &&
							this.notesdata &&
							this.notesdata[i].text
						) {
							formData.append(`notesdata[${i}][id]`, this.notesdata[i].id);
						}
						if (this.notesdata && this.notesdata[i] && this.notesdata && this.notesdata[i].text) {
							formData.append(`notesdata[${i}][text]`, this.notesdata[i].text);
						}
						if (this.notesdata[i].check == true) {
							this.notesdata[i].check = 1;
						} else {
							this.notesdata[i].check = 0;
						}
						formData.append(
							`notesdata[${i}][check]`,
							this.notesdata[i].check ? this.notesdata[i].check : 0
						);
					}
				}
			}
			let msg = "Success ! Task Created successfully.";
			let requestTYPE = POST;
			let requestURL = "task";

			if (this.taskData && this.taskData.id) {
				requestURL = `task/${this.taskData.id}`;
				msg = "Success ! Task Updated successfully.";
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [{ model: true, message: msg }]);
					this.$refs.form.reset();
					this.task.description = "";
					TaskEventBus.$emit("refresh", true);
					this.$emit("updateTask", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		/* 	saveTaskData() {
			this.pageLoading = true;
			this.task.related_id = this.task.related_id ? this.task.related_id : this.relatedId;
			this.task.related_to = this.task.related_to ? this.task.related_to : this.related;
			this.task.lead_id = this.task.lead_id ? this.task.lead_id : this.relatedId;

			this.$store
				.dispatch(POST, { url: `task`, data: this.task })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task added successfully." },
					]);
					this.$refs.form.reset();
					this.task.description = "";
					this.$emit("updateTask", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateTaskData() {
			this.pageLoading = true;

			this.task.related_id = this.task.related_id ? this.task.related_id : this.relatedId;
			this.task.related_to = this.task.related_to ? this.task.related_to : this.related;
			this.$store
				.dispatch(PUT, { url: `task/${this.taskData.id}`, data: this.task })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task updated successfully." },
					]);
					this.$emit("updateTask", true);
					this.$refs.form.reset();
					this.task.description = "";
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
	},
	computed: {
		...mapGetters(["errors", "currentUser"]),
	},
	created() {
		this.getRelatedField(false);
	},
	mounted() {
		this.getTaskSetting();

		if (this.titleStatus != "Update") {
			this.task.start_date = new Date().toISOString().substr(0, 10);
		}
		if (this.task.due_date && this.titleStatus != "Update") {
			this.task.due_date = new Date().toISOString().substr(0, 10);
		}
		if (this.titleStatus != "Update") {
			this.task.reminder_start_date = this.formatDateTimeRaw(new Date());
		}
		if (this.titleStatus != "Update") {
			this.task.reminder_end_date = new Date().toISOString().substr(0, 10);
		}
		if (this.titleStatus != "Update") {
			this.task.reminder_end_date = new Date().toISOString().substr(0, 10);
		}

		this.int(this.taskData);
		this.int1(this.attendies);
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>
<style scoped></style>
